@import './colors.scss';

//store it in variable first
$font-main: 'Share Tech Mono', sans-serif;

//to use it   
body, .ui {
  font-family: $font-main !important;
  color: $c-main !important;
}

h1, h2, h3, h4, h5 {
  font-family: $font-main !important;
}

body {
  background-color: $c-black;
  color: $c-main;
}

pre {
  white-space: pre-wrap;
}

.ui.modal, .ui.container {
  // disallow text selection
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

}

i.icons .corner.icon {
  text-shadow: none;
}

.ui.button {
  background-color: $c-main !important;
  color: $c-main-lighter !important;
  border-radius: 16px 4px 16px 4px;

  &:hover {
    background-color: $c-main-light !important;
  }

  &.dangerBtn {
    background-color: $c-red-darker !important;
    color: $c-red-light !important;

    &:hover {
      background-color: $c-red-dark !important;
      color: $c-red-lighter !important;  
    }
  }

  &.alertBtn {
    background-color: $c-red !important;
    color: $c-red-lighter !important;

    &:hover {
      background-color: $c-red-dark !important;
      color: $c-red-light !important;  
    }
  }
}

.ui.grid.topGrid {
  min-width: 1200px;
}

h1.ui {
  color: $c-main-light !important;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.logo {
  position: relative;

  .logoLayer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.loading {
  width: 100%;
  height: calc(100vh - 80px);
  text-align: center;

  .loadingLogo {
    display: inline-block;
    margin-top: 15%;
  }
}

.ui.menu.mainMenu {
  background-color: $c-main;
  color: $c-main-darker;
  border-radius: 0;

  .mainMenuLogo {
    margin-right: 15px;
  }

  .menuTitle {
    font-size: 28px;
    font-weight: 700;
    color: $c-main-darkest;
  }

  .menuLink {
    font-size: 18px;
    font-weight: 700;
    color: $c-main-darker;

    &:hover {
      color: $c-main-lighter;
    }
  }

  .academyStats {
    text-align: right;
    width: 120px;
    color: $c-main-darker;
    font-size: 18px;
  }

  .toggleBtnOn {
    color: $c-main-lighter;
  }
  .toggleBtnOff {
    color: $c-main-dark;
  }
}

.teamList {
  width: 480px;
}

.academyView {
  .sidebarColumn {
    padding: 10px 0 !important;

    .sidebarBtn {
      width: 100%;
      padding: 7px;
      margin-top: 8px;
      text-align: center;
      font-size: 12px;
      color: $c-main-darkest !important;

      .centered {
        margin: auto !important;
      } 
    }

    .starMapBtn {
      height: 100px;
      margin-top: 40px;
      background-color: $c-blue-darker !important;
      color: $c-blue-lightest !important;
      border: 1px solid $c-blue-lighter;
      font-weight: 700;

      &:hover {
        background-color: $c-blue-dark !important;
      }

      .starMapBtnImg {
        height: 58px;
        width: 58px;
        margin-top: 7px;
        border: 1px solid $c-blue;
        border-bottom-right-radius: 12px;
        background-color: $c-blue-darkest;
      }
    }

    .battleBtn {
      font-size: 16px;
      font-weight: 700;
    }

    .settingsBtn {
      position: absolute;
      bottom: 0;
    }

    .storyBtn {
      margin-top: 40px;
    }

    .sidebarLogo {
      margin-top: 9px;

      .logo {
        margin: auto;
      }
    }

    .timeDisplay, .credits {
      margin-top: 10px;
      padding: 4px 2px;
      background-color: $c-main-darkest;
      border: 1px solid $c-main-darker;
      border-radius: 8px 4px 8px 4px;
      color: $c-main-lighter;
      text-align: center;

      .datePart {
        font-size: 12px;
      }
    }
  }

  .academyRoom {
    position: relative;
    border-top: 4px solid $c-main-darker;
    border-bottom: 4px solid $c-main-darker;
    background-color: $c-main-verydark;
    padding: 4px;
    margin-bottom: 8px;
    
    .roomName {
      height: 20px;
      width: 100%;
      background-color: $c-main-verydark;
      color: $c-main-light;
      margin-bottom: 8px;
      padding-top: 4px;
      font-size: 18px;
      font-weight: 700;

      &.subhead {
        color: $c-main;
      }

      .roomNote {
        float: right;
        margin-right: 6px;;
        color: $c-main-dark;
        font-size: 14px;
        font-weight: normal;
      }
    }

    .roomAttrIcons {
      top: -1px;
      position: relative;
      margin-right: 6px;

      .roomAttrIcon {
        margin-right: 4px;
        font-size: 14px;
        opacity: 0.6;
      }
    }

    .roomContents {
      position: relative;
      height: calc(100% - 24px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .emptyMsg {
        width: 100%;
        text-align: center;
        position: relative;
        top: 30%;
        color: $c-main-darker;
        font-size: 11px;
      }
    }

    &.barracks {
      height: 430px;
    }

    &.hangar {
      height: 280px;
    }

    &.infirmary {
      height: 280px;

      .roomContents {
        height: auto;
      }
      .infirmaryWorkers {
        height: 60px;
      }
      .infirmaryPatients {
        height: 120px;
      }

      &.expanded {
        height: 340px;

        .infirmaryPatients {
          height: 180px;
        }
      }
    }

    &.repairBay {
      height: 245px;

      .roomContents {
        height: auto;
      }
      .repairBayWorkers {
        height: 60px;
      }
      .repairBayMechs {
        height: 80px;

      }
      
      &.expanded {
        height: 315px;

        .repairBayMechs {
          height: 150px;
        }
      }
    }

    &.simulator {
      height: 110px;

      &.expanded {
        height: 170px;
      }
    }

    &.recRoom {
      height: 170px;

      &.expanded {
        height: 230px;
      }
    }

    &.factory {
      height: 170px;

      &.expanded {
        height: 230px;
      }
    }

    &.factoryQueue {
      color: $c-main-lighter;
      cursor: pointer;

      &:hover {
        background-color: $c-main-darkest;
      }

      .jobName {
        display: inline;
        margin-left: 4px;

        &.empty {
          color: $c-main;
        }
      }

      .jobProgress {
        float: right;
        padding: 4px;

        .progressBar {
          height: 10px;
          width: 100px;

          .healthBarMax {
            background-color: $c-main;

            .healthBarCurr {
              background-color: $c-main-light;
            }
          }
        }
      }
    }

    .pilotCard, .mechCard {
      .teamBadge {
        float: right;
        width: 20px;
        height: 10px;
        font-size: 8px;
        color: $c-main-darkest;
        font-weight: 700;
        padding: 2px 4px;
        line-height: 0.9;
        text-align: center;
        border-radius: 5px;
        opacity: 0.7;
      }
    }

    .pilotCard {
      float: left;
      position: relative;
      width: 160px;
      height: 57px;
      margin-bottom: 4px;
      margin-right: 4px;
      padding: 6px;
      cursor: pointer;
      background-color: $c-main-darkest;
      border: 2px solid $c-main-dark;
      border-radius: 12px 4px;

      .teamBadge {
        margin-right: 4px;
      }

      .advanceBadge {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: $c-main-dark;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: $c-main-lighter;
        text-align: center;
        z-index: 1000;

        .icon {
          display: block;
          position: relative;
          top: 4px;
          left: 3px;
        }
      }

      &:hover {
        border-color: $c-main-light;

        .advanceBadge {
          background-color: $c-main-light;
          color: $c-main-lightest;
        }
      }

      .portrait {
        float: left;
        margin-right: 7px;
        border-top-left-radius: 6px;
        overflow: hidden;
      }

      .pilotName {
        position: relative;
        line-height: 1.2;
        color: $c-main-lighter;
      }

      .rankPips {
        position: relative;
        top: 2px;
      }

      .pilotHealth, .pilotEnergy {
        float: right;
        width: 4px;
        height: 40px;
        border-radius: 2px;
      }

      .pilotHealth {
        .healthBarCurr {
          background-color: $c-red;
        }
        &.full {
          .healthBarCurr {
            background-color: $c-main;
          }
        }
      }
      .pilotEnergy {
        margin-right: 2px;

        .healthBarCurr {
          background-color: $c-blue;
        }
      }
    }

    .mechCard {
      float: left;
      position: relative;
      width: 160px;
      height: 76px;
      margin-bottom: 4px;
      margin-right: 4px;
      padding: 6px;
      cursor: pointer;
      background-color: $c-main-darkest;
      border: 2px solid $c-main-dark;
      border-radius: 12px 4px;

      .teamBadge {
        top: 6px;
        position: relative;
      }

      .portrait {
        float: left;
        margin-right: 7px;
        border-top-left-radius: 6px;
        overflow: hidden;
      }

      .rankBadge {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background: $c-main-dark;
        border-bottom-left-radius: 3px;
        color: $c-main-lighter;
        text-align: center;
        z-index: 1000;
      }

      .armorBars {
        margin-top: 4px;

        .mechHealth {
          float: left;
          width: 8px;
          height: 16px;
          margin-right: 2px;
          border-radius: 2px;

          .healthBarCurr {
            background-color: $c-red;
          }
          &.full {
            .healthBarCurr {
              background-color: $c-gray-50
            }
          }
        }
      }

      &:hover {
        border-color: $c-main-light;

        .rankBadge {
          background-color: $c-main-light;
          color: $c-main-lightest;
        }
      }
    }
  }
}

.mechStatsTable {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 12px;
  color: $c-main-light;

  td {
    padding: 0 10px 0 0;
  }

  .mechStatLabel {
    font-weight: 700;
    color: $c-main-lighter;
  }

  .mechStatValue {
    &.damaged {
      color: $c-red;
    }
  }
}

.ui.menu.rosterMenu {
  background-color: transparent;
  color: $c-main;
  border-radius: 0;

  .menuItem {
    font-size: 17px;
    padding: 13px;
    font-weight: 700;
    border-radius: 12px 4px 12px 4px;
    color: $c-main-light;
    height: 39px;
    margin-top: 5px;

    &.active {
      pointer-events: none;
      cursor: auto;
      background-color: $c-main;
      color: $c-main-lighter;
    }

    &:hover {
      background-color: $c-main-darkest !important;
      color: $c-main-lighter;
    }
  }
}

.pilotTable, .teamTable {
  width: 100%;
}

.createBtn {
  float: right;
}

.genderIcon {
  color: $c-main-light;
}

.dragPortrait {
  cursor: move;
  display: inline-block;
}

.portraitFrame {
  position: relative;

  .portraitLayer {
    position: absolute;
    top: 0;
    left: 0;

    &.flipH {
      -webkit-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

.ui.dropdown {

  &.selection {
    padding: 8px 6px;
    font-size: 12px;
    background-color: $c-main-darkest;
    color: $c-main-light !important;
    border: 1px solid $c-main-light;
    border-radius: 10px 4px;

    &:focus, &:hover {
      border-color: $c-main-lighter;
      color: $c-main-light;
    }

    .text {
      &.default {
        color: $c-main-dark;
      }
    }
  
    .menu {
      border-color: $c-main-light !important;
      border-radius: 0 0 10px 4px;

      .item {
        padding: 8px 6px !important;
        background-color: $c-main-verydark !important;
        border-bottom-color: $c-main-light !important;
        border-left-color: $c-main-light !important;
        border-right-color: $c-main-light !important;
        border-top-color: $c-main-dark !important;
        font-size: 12px;
      }
    }

    &.active {
      border-left-color: $c-main-light !important;
      border-right-color: $c-main-light !important;
      border-top-color: $c-main-light !important;

      .menu {
        border-color: $c-main-light !important;

        .item {
          padding: 8px 6px !important;
          background-color: $c-main-verydark !important;
          border-bottom-color: $c-main-light !important;
          border-left-color: $c-main-light !important;
          border-right-color: $c-main-light !important;
          font-size: 12px;

          &:hover {
            background-color: $c-main-darkest !important;

            .text {
              color: $c-main-lighter;
            }
          }
        }
      
        &:hover {
          border-color: $c-main-light !important;
        }
      }

      .text {
        color: $c-main-light;
        
        &:not(.default) {
          color: $c-main-light;
        }
      }

      &:focus, &:hover {
        border-color: $c-main-lighter;
      }
    }
  }
}

.teamList {
  .teamListHeader {
    h1 {
      display: inline;
    }

    .moduleDropdown {
      float: right;
      width: 190px;
      height: 30px;
      min-width: unset;
      min-height: unset;
      margin-right: 15px;
      margin-top: 4px;

      .icon {
        padding: 8px;
      }
    }
  }

  .teamMenu {
    background-color: transparent;

    .teamItem {
      width: 16.6%;
      padding: 0 4px;
      
      .teamNum {
        width: 100%;
        font-weight: 700;
        color: $c-main-darkest;
        text-align: center;
        padding: 8px 6px;
        border-radius: 12px 4px 12px 4px;
        filter: brightness(0.75) saturate(0.5);

        &:hover {
          filter: brightness(1.25);
        }
      }

      &.selected {
        .teamNum {
          pointer-events: none;
          filter: brightness(1.0);
          color: $c-white;
          font-weight: 700;
        }
      }

      &.disabled {
        pointer-events: none;
        .teamNum {
          background-color: $c-gray-20;
          color: $c-gray-30;
        }
      }
    }
  }
}

.pilotList, .mechList {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  
  .pilotRow, .mechRow {
    float: left;
    clear: left;
    width: 100%;
    margin-bottom: 4px;
    background-color: $c-main-darkest;
    color: $c-main-light;;
    border: 1px solid transparent;
    border-left: 6px solid $c-main-light;
    padding-left: 8px;
    cursor: pointer;

    &:hover {
      border-color: $c-main-light;
    }
    
    .pilotTeam, .mechTeam {
      float: left;
      width: 24px;
      height: 40px;
      font-size: 22px;
      margin-right: 8px;
      padding-top: 11px;
      padding-left: 4px;
      font-weight: 700;
      border-radius: 0 20px 20px 0;
      background-color: $c-main;
      color: $c-main-darkest;
    }

    .pilotInfo {
      float: left;
      width: 350px;

      .pilotName {
        float: left;
        font-size: 14px;
        font-weight: 700;
      }
      .pilotData {
        float: left;
        clear: left;
        width: 100%;
        font-size: 12px;

        .deletePilot {
          float: left;
        }

        .rankPips {
          position: relative;
          top: 6px;
        }

        .assignment {
          float: right;
          height: 18px;
          font-size: 11px;
          color: #333333cc;
          font-weight: 700;

          .slot {
            display: inline-block;
            border-radius: 9px 0 0 3px;
            padding: 0 8px;
            padding-right: 6px;
            background-color: $c-main-verydark;
            color: $c-main-light;
            border-top: 1px solid $c-main;
            border-left: 1px solid $c-main;
            border-bottom: 1px solid $c-main;
          }

          .teamId {
            display: inline-block;
            border-radius: 0 3px 9px 0;
            border-width: 1px;
            border-style: solid;
            width: 30px;
            text-align: center;          
          }
        }
      }
    }
  
    .mechInfo {
      float: left;
      padding-right: 8px;

      .mechName {
        float: left;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
        color: $c-main-lighter;

        .mechIcon {
          display: inline-block;
          margin-right: 8px;
        }
      }

      .mechWeapon {
        float: left;
        clear: left;
        font-size: 14px;
      }
    }
    
    .pilotPortrait, .mechView {
      float: right;
    }

    .attrBlock, .skillBlock {
      float: right;
      margin-right: 12px;
    }
  }

  .deleteFromListBtn {
    float: right;
    margin-top: 2px;
    color: $c-main-darker;

    &:hover {
      color: $c-main;
    }
  }

  .pilotRow {
    height: 44px;
  }
  .mechRow {
    height: 90px;
  }

  .mechView {
    margin: 5px;
  }
}

.pilotSlot, .mechSlot {
  width: 100%;
  background-color: $c-main-darkest;
  padding: 6px;
  position: relative;

  .pilotSlotHeader, .mechSlotHeader {
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    padding: 2px 4px;
    background-color: $c-main-dark;
    color: $c-main-darkest;

    .iconBtn {
      color: $c-main-darkest;

      &:hover {
        color: $c-main-light;
      }
    }
  }

  .rankPips {
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;

    .pip {
      color: $c-yellow;
    }
  }
}

.pilotSlot {
  .pilotSlotContent {
    float: left;
    height: 90px;
    margin: 6px 0;

    .pilotPortraitFrame {
      float: left;
      position: relative;
    }

    .pilotSlotInfo {
      float: left;
      width: 120px;
      margin-top: 14px;
      padding-left: 12px;
    }
  }

  .pilotSlotName {
    float: left;
    width: 100%;
    height: 16px;
    color: $c-main-lighter;
  }
}

.mechSlot {
  .mechDropSlot {
    position: relative;
  }
  
  .mechSlotContent {
    float: left;
    width: 100%;
    height: 106px;
    margin: 6px 0;

    .mechSlotInfo {
      float: left;
      width: 110px;
      margin: 0 4px;
      font-weight: 700;
      color: $c-main-light;

      .weapon {
        margin-top: 8px;
        font-size: 12px;
      }
    }
  }
}

.skillBlock {
  text-align: center;
  font-weight: 700;
  margin-top: 4px;

  .skillValues {
    float: left;
    font-size: 12px;
    height: 15px;

    .skillValue {
      width: 16px;
      display: inline-block;
      color: $c-main-lighter;

      &.blocked {
        color: $c-gray-60;
      }
    }
  }

  .skillIcons {
    float: left;
    clear: left;

    .skillIcon {
      width: 16px;
      display: inline-block;
      color: $c-main-dark;

      &.blocked {
        color: $c-gray-60;
      }
    }
  }
}

.attrBlock {
  height: 42px;
  font-weight: 700;
  text-align: center;
  
  .attrValues {
    float: left;

    .attrBar {
      position: relative;
      display: inline-block;
      width: 12px;
      height: 10px;

      .attrFill {
        position: absolute;
        width: 10px;
        bottom: 0;
        left: 1px;
      }
    }
  }

  .attrIcons {
    float: left;
    clear: left;

    .attrIcon {
      float: left;
      width: 12px;
      height: 10px;
      font-size: 10px;

      &.bonusMalus {
        font-size: 12px;
      }
    }
  }
}

.rankPips {
  float: left;

  .pip {
    float: left;
    color: $c-main;
  }
}

.ui.modal {
  background-color: transparent;

  .header {
    border-radius: 30px 10px 0 0 !important;
  }

  .actions {
    border-radius: 0 0 30px 10px !important;
  }
}

.pilotInfoModal, .mechInfoModal, .messageModal, .guideModal, .eventModal, .mechPurchaseModal, .relationshipsModal, .starMapModal, .upgradesModal, .factoryQueueModal, .minigameModal, .settingsModal {

  .header {
    background-color: $c-main-darker !important;

    h1 {
      display: inline;
    }
  }
  .content {
    background-color: $c-main-verydark !important;
    min-height: 300px;
  
    .ui, p {
      color: $c-main-lighter !important;
    }

    b {
      color: $c-main-lighter;
    }  

    .noData {
      position: relative;
      top: 120px;
      color: $c-main-lighter;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .actions {
    background-color: $c-main-darker !important;
    color: $c-main-lighter;
  }

  .sectionHeader {
    margin-bottom: 6px;
    font-size: 18px;
    font-weight: 700;
    color: $c-main-lighter;

    .iconBtn {
      margin-left: 8px;
    }
  }

  .attrRow {
    float: left;

    .attrLabel {
      float: left;
      clear: left;
      width: 90px;
      font-weight: 700;
    }
  
    .attrBar {
      float: left;
      width: 119px;
      height: 20px;
      border: 1px solid $c-main-darker;
  
      .attrBarFill {
        position: relative;
        float: left;
        height: 100%;
        width: 9px;
        border-right: 1px solid $c-main-darkest;
  
        .progress {
          position: absolute;
          bottom: 0;
          width: 100%;
          opacity: 0.2;
        }
      }
    }

    .iconBtn {
      margin-left: 8px;
    }
  }

  .skillRow {
    float: left;

    .skillLabel {
      float: left;
      clear: left;
      width: 140px;
      font-weight: 700;
      color: $c-main-light;
    }

    .skillBar {
      float: left;
      width: 47px;
      height: 20px;
      border: 1px solid $c-main-darker;

      .skillBarFill {
        float: left;
        height: 100%;
        width: 9px;
        border-right: 1px solid $c-main-darkest;
        background-color: $c-main-lighter;

        &.blocked {
          background-color: $c-gray-60;
        }
      }
    }

    .iconBtn {
      margin-left: 8px;
    }
  }

  .metricLabel {
    display: inline-block;
    width: 110px;
    font-weight: 700;
    color: $c-main-light;

    &.small {
      width: 60px;
    }
  }

  .metricValue {
    display: inline-block;
    width: 120px;
    font-weight: 700;
    color: $c-main-lighter;
  }

  .suitColorLabel, .mechColorLabel {
    float: left;
    margin-right: 8px;
    font-weight: 700;
    color: $c-main-light; 
  }

  .pilotInfoPortrait {
    float: right;
    border: 4px solid $c-main-lighter;
  }

  .portraitControls {
    float: right;
    width: 208px;
    margin-top: 6px;
  }

  .suitColorControl {
    display: inline-block;  
    padding-top: 3px;
    
    .suitColorLabel {
      padding-top: 5px;
    }
    .suitColorPicker {
      float: left;
      border-radius: 25px;
      overflow: hidden;
    }
  }

  .mechColorPicker {
    float: left;
    width: 200px;
  }

  .modalMenu {
    background-color: transparent;

    .menuItem {
      margin-right: 20px;
      padding: 10px 14px;
      border-radius: 22px 4px 22px 4px !important;
      background-color: $c-main-darker !important;
      color: $c-main-light !important;
      font-size: 20px;
      font-weight: 700 !important;

      &.active, &:hover {
        background-color: $c-main-dark !important;
      }

      .menuLogo {
        margin-right: 8px;
      }
    }
  }
}

.messageModal, .eventModal, .guideModal {
  .content {
    font-size: 16px !important;
  }
}

.messageModal {
  .content {
    max-height: calc(100vh - 220px);
    overflow: auto;
  }

  .storyList {
    .storySectionHeader {
      font-size: 18px;
      font-weight: 700;
      background-color: $c-main-darker;
      color: $c-main-light;
      margin-bottom: 14px;
      padding: 4px 10px;
      border-radius: 12px 4px;
    }

    .storyTable {
      margin-bottom: 20px;

      th {
        color: $c-main-lighter;
        border-bottom: 1px solid $c-main-light;
      }
  
      td {
        vertical-align: top;
  
        &.storyName {
          width: 200px;
          color: $c-main-lighter;
        }
        &.pilotName {
          width: 200px;
          color: $c-main-lighter;
        }
      }
    }
  }

  .tutorial {
    .tutorialImg {
      border: 2px solid $c-main;
      margin: 10px;
      border-radius: 16px 4px;
    }
  }
}

.guideModal {
  .sectionTab, .itemTab {
    padding: 4px 16px;
    margin-bottom: 4px;
    font-weight: bold;
    border-radius: 14px 4px;
    cursor: pointer;
  }

  .sectionTab {
    float: left;
    width: 100%;
    background-color: $c-main-dark;
    font-size: 18px;

    &:hover {
      background-color: $c-main;
    }
  }

  .itemTab {
    float: right;
    background-color: $c-main-darker;
    color: $c-main;
    width: calc(100% - 12px);
    font-size: 16px;

    &.active {
      color: $c-main-lighter;
    }

    &:hover {
      background-color: $c-main-dark;
      color: $c-main-lighter;
    }
  }

  .content {
    height: calc(100vh - 200px);
    overflow-y: auto;

    .guideHeader {
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: 700;
      color: $c-main-lighter;
    }

    li {
      margin-bottom: 12px;
    }

    .roomNotes {
      color: $c-main-light;
    }
  
    .roomAttrs {
      margin-bottom: 14px;
      font-weight: 700;
    }  

    .guideImg {
      border: 2px solid $c-main;
      margin: 0 10px 10px 10px;
      border-radius: 16px 4px;
    }
  }
}

.eventModal {
  .pilotInfo {
    text-align: center;

    .portraitFrame {
      display: inline-block;
    }
    
    .pilotName {
      font-weight: 700;
      font-size: 20px;
      margin: 10px 0;
    }

    .attrBlock, .skillBlock {
      display: inline-block;
  
      .attrBar {
        height: 20px;
      }
    }

    .pilotQuirks {
      margin-top: 10px;
      font-size: 12px;
    }

    .useFame {
      margin-top: 20px;
      font-size: 14px;
    }
  }

  .eventBody {
    display: flex !important;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-content: space-between;
    align-content: space-between;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    max-height: 75vh;
    overflow: auto;

    .otherPerson {
      float: right;
      margin: 0px 0px 10px 10px;
      width: 70px;

      .otherName {
        text-align: center;
      }
    }

    .optionSet {
      flex-basis: 100%;
  
      .optionItem {
        margin-top: 15px;
        background-color: $c-main-verydark;
        border-radius: 16px 6px;
        border: 1px solid $c-main;
        padding: 8px;
        cursor: pointer;
  
        &:hover {
          background-color: $c-main-darkest;
          border-color: $c-main-light;
        }

        .optionStats {
          width: 100%;
          margin-top: 8px;
          text-align: right;
          font-size: 14px;
          color: $c-main;

          .medium {
            color: yellow;
          }
          .hard {
            color: $c-red-light;
          }
        }
      }
    }

    .outcome {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 15px;

      &.success {
        color: $c-main-lightest;
      }
      &.failure {
        color: $c-red-dark;
      }
    }

    .reward {
      margin-top: 15px;
    }
  }
}

.starMapModal {
  .content {
    position: relative;
    padding: 0 !important;
    height: 600px !important;
    background-color: $c-black !important;
  }

  .starText {
    fill: $c-gray-40;
    pointer-events: none;
    stroke-width: 0.5;
    font-weight: 700;
    text-align: center;
    transition: opacity 0.2s linear;

    &.visited {
      fill: $c-red;
    }

    &.beacon {
      fill: $c-main-light;
    }
  }

  .starNode {
    stroke: white;
    stroke-width: 0;
    pointer-events: none;

    &.glow {
      opacity: 0.6;
      filter: blur(1px);
    }
  }

  .storyNode {
    opacity: 0;
    fill: $c-red;
    filter: blur(1px);

    &.visible {
      opacity: 0.6;
    }
  }

  .hitBox {
    fill: transparent;
    stroke-width: 0;
    cursor: pointer;
  }

  .selectedStar {
    fill: $c-white;
    filter: blur(2px);
    opacity: 0;

    &.visible {
      opacity: 0.6;
    }
  }

  .dangerZone {
    pointer-events: none;
    filter: blur(20px);
    opacity: 0;

    @for $i from 0 through 5 {
      &.danger-#{$i} {
        fill: nth($dangerColors, $i + 1);
      }
    }

    &.visible {
      opacity: 0.4;
    }
  }

  .orbitStar {
    opacity: 0.4;
  }

  .temp-1 {
    fill: rgb(252, 101, 101);
  }
  .temp-2 {
    fill: rgb(252, 192, 101);
  }
  .temp-3 {
    fill: rgb(229, 252, 101);
  }
  .temp-4 {
    fill: rgb(101, 252, 114);
  }
  .temp-5 {
    fill: rgb(101, 179, 252);
  }
  .temp-6 {
    fill: rgb(253, 127, 247);
  }

  .links {
    stroke: $c-gray-20;

    &.path {
      stroke: $c-yellow;
    }
    &.local {
      stroke: $c-blue;
    }
  }

  .graphControls {
    .graphDangerBtn, .graphStoryBtn {
      cursor: pointer;

      .btnBorder {
        stroke-width: 1px;
        stroke: $c-main-light;
        fill: transparent;
      }

      &:hover {
        .btnBorder {
          stroke: $c-main-lighter;
        }
      }
    }

    /* Tooltip text */
    .tooltip .tooltiptext {
      opacity: 0;
      fill: $c-main-lighter;
      text-align: center;
      transition: opacity 0.2s linear;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
      opacity: 1;
    }


  }

  .starInfo {
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: 240px;
    max-height: 60%;
    border-radius: 20px 8px 20px 8px;
    border: 1px solid $c-main-light;
    padding: 8px;
    background-color: $c-main-darkest;
    opacity: 0.9;

    .starName {
      padding-bottom: 6px;
      margin-bottom: 6px;
      border-bottom: 1px solid $c-main;
      font-size: 18px;
      font-weight: 700;
      color: $c-main-lighter;
      text-align: center;
    }

    .planetList {
      .planetTable {
        width: 100%;

        .planetIconCell {
          width: 45px;
        }

        .launchIconCell {
          text-align: right;
        }
      }

      .planetInfo {
        height: 45px;

        .planetIcon {
          float: left;
          width: 30px;
          height: 30px;
          overflow: hidden;
          margin-right: 12px;
          border-radius: 15px;
          filter: drop-shadow(3px 3px 3px $c-black);

          &.jungle {
            background-color: #3e6302;
          }
          &.lava {
            background-color: #e24000;
          }
          &.airless {
            background-color: #b9b9b9;
          }
          &.desert {
            background-color: #ddb944;
          }
          &.temperate {
            background-color: #009c22;
          }
          &.swamp {
            background-color: #057251;
          }
          &.water {
            background-color: #051eac;
          }
          &.ice {
            background-color: #6ecbf7;
          }      
        }

        .planetName {
          padding-top: 4px;
          font-size: 16px;
          font-weight: 700;
          white-space: nowrap;

          @for $i from 0 through 5 {
            &.danger-#{$i} {
              color: nth($dangerColors, $i + 1);
            }
          }
        }

        .planetHighlight {
          position: absolute;
          top: 3px;
          left: 5px;
          width: 15px;
          height: 15px;
          border-radius: 5px;
          background-color: $c-white;
          filter: blur(6px);
        }

        .launchBtn {
          margin-top: 3px;
          margin-left: 8px;

          &.story {
            color: $c-red;
          }
        }
      }
    }

    .travelBtn {
      text-align: center;
      padding-bottom: 8px;
    }

    .jumpCost {
      padding: 4px 12px;
      font-size: 20px;
      color: $c-main-lighter;
    }
  }
}

.roomIcon {
  top: -1px;
  position: relative;
  // color: $c-main-dark;
}

.pilotInfoModal, .mechInfoModal {
  .infoSection {
    margin-bottom: 20px;
  }
}

.pilotInfoModal {
  .statsContainer {
    min-height: 300px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .statItem {
      width: 195px;
      height: 54px;
      margin: 12px 12px 0 0;
      border: 1px solid $c-main;
      border-radius: 16px 4px 16px 4px;
      overflow: hidden;
      font-size: 16px;

      .statLabel {
        padding: 2px 10px;
        background: $c-main;
        color: $c-main-darkest !important;
        font-weight: 700;
      }
      .statValue {
        padding: 2px 10px;
        color: $c-main-light;

        .statDetail {
          float: right;
          font-size: 14px;
          color: $c-main-dark;
        }
      }
    }
  }

  .quirkList {
    height: 40px;
  }
}

.weaponStatsTable {
  width: 100%;

  .statLabel {
    font-weight: 700;
    color: $c-main-lighter;
    text-align: right;
    padding-right: 10px;
  }
}

// .mechInfoModal {

// }

.mechPurchaseModal {
  .showroom {
    float: left;
    margin-right: 20px;
    
    .mechModel {
      color: $c-main-lightest;
      font-size: 22px;
      font-weight: 700;
    }

    .mechImage {
      display: inline-block;
      border: 2px solid transparent;

      &.selected {
        border-color: $c-main-light;
      }
      
      &:hover {
        border-color: $c-main-lighter;
      }
    }
  }

  .availFunds {
    display: inline-block;
    margin-left: 14px;
  }
  
  .rankButtons {
    .rankBtn {
      background-color: $c-main-dark !important;
      color: $c-main-light;
      padding: 8px 0;
      width: 17%;

      &.active, &:hover {
        background-color: $c-main !important;
        color: $c-main-lighter;
      }
    }
  }

  .price {
    margin-top: 30px;
    font-size: 18px;
  }
}

.ui.large.modal.relationshipsModal {
  left: unset !important;

  .content {
    padding: 0;
    height: 600px;

    .node circle {
      fill: #888888;
      stroke: #fff;
      stroke-width: 2px;
    }

    #theGraph {
        .node {
          transition: opacity 0.2s linear;
        }
        .nodeText {
          fill: $c-main-light;
          // stroke: $c-main-dark;
          stroke-width: 0.5;
          font-size: 14px;
          font-weight: 700;
          text-align: center;
          transition: opacity 0.2s linear;
        }
        
        .link {
          stroke-opacity: .6;
          transition: stroke-opacity 0.2s linear;
        }

        .inactive {
          opacity: 0.1;
          stroke-opacity: 0.1;
        }
    }
  }
}

.upgradesModal {
  .upgradeIcon {
    margin-right: 6px;
  }

  .upgradesTable {
    width: 100%;
    border-spacing: 0 6px;
    border-collapse: separate;

    td {
      padding: 4px;
      font-size: 18px;
      background-color: $c-main-darkest;
    }

    .upgradeInfo {
      .upgradeName {
       font-weight: 700;; 
       border-radius: 20px 0 0 6px;
       padding-left: 12px;
      }

      .upgradePrice {

        .upgradePriceValue {
          display: inline-block;
          width: 20px;
          text-align: right;
        }
      }

      .upgradePurchase {
        width: 80px;
        border-radius: 0 6px 20px 0;
      }
    }
  }

  .upgradeNotes {
    position: relative;
    height: 100%;
    padding-top: 12px;

    .upgradeNotesHeader {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .upgradeNotesBody {
      font-size: 18px;
    }
    .availableXenos {
      font-size: 18px;
      position: absolute;
      bottom: 16px;
    }
  }
}

.factoryQueueModal {
  .modulesTable {
    width: 100%;
    border-spacing: 0 6px;
    border-collapse: separate;

    th {
      text-align: center;
      font-size: 16px;
      color: $c-main-dark;
    }

    td {
      padding: 4px;
      font-size: 18px;
      background-color: $c-main-darkest;
    }

    .moduleInfo {
      .moduleName {
       font-weight: 700;; 
       border-radius: 20px 0 0 6px;
       padding-left: 12px;
      }

      .moduleCount {
        text-align: center;
        cursor: pointer;
        transition: all 0.1s linear;

        &:hover {
          background-color: $c-main-dark;
        }

        &.disabled {
          pointer-events: none;
          color: $c-main;
        }
      }

      .modulePrice {

        .modulePriceValue {
          display: inline-block;
          width: 20px;
          text-align: right;
        }
      }

      .endCap {
        border-radius: 0 6px 20px 0;
      }
    }
  }

  .queue {
    height: 350px;

    .queueHeader {
      font-size: 22px;
      font-weight: 700;
      border-bottom: 1px solid $c-main;
    }
  
    .queueItem {
      padding: 4px 0;
      font-size: 16px;
      border-bottom: 1px solid $c-main-darker;
    }
  }

  .moduleNotes {
    position: relative;
    height: 160px;
    padding-top: 12px;

    .moduleNotesHeader {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 16px;

      .cost {
        float: right;
        width: 120px;
      }
    }
    .moduleNotesBody {
      font-size: 18px;
    }
  }

  .availableXenos {
    margin-top: 12px;
    font-size: 18px;
  }
}

.settingsModal {
  .settingItem {
    margin-bottom: 8px;
  }

  .settingLabel {
    display: inline-block;
    position: relative;
    top: 3px;
    width: 140px;
    font-size: 18px;
    font-weight: 700;
    color: $c-main-lighter;
  }

  .settingControl {
    display: inline-block;
    width: 200px;
  }

  .settingBtnGrp {
    .settingBtn {
      background-color: $c-main-dark !important;
      color: $c-main-light !important;
      padding: 8px 14px;

      &:first-child {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 14px;
      }

      &:hover {
        background-color: $c-main !important;
        color: $c-main-lighter !important;
      }

      &.active {
        background-color: $c-main-light !important;
        color: $c-main-lightest !important;
        pointer-events: none;
      }

      &.volumeUp {
        padding-left: 4px;
      }
      &.volumeDown {
        padding-right: 4px;
      }
      &.volumeDisplay {
        background-color: $c-main-darker !important;
        pointer-events: none;
      }
    }
  }
}

.minigameDimmer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000CC;

  .minigameModal {
    position: relative;
    top: calc(50vh - 250px);
    height: 500px;
    width: 500px;
    margin: auto;
    background-color: $c-main-verydark;
    border: 2px solid $c-main;
    border-radius: 18px 6px;

    .minigameHeader {
      position: absolute;
      top: 0;
      z-index: 100;
      width: 100%;
      text-align: center;
      padding: 20px;
      font-weight: bold;
      color: $c-main-light;
      font-size: 18px;
    }

    .timerMax {
      fill: $c-main-dark;
    }
    .timerCurr {
      fill: $c-main;
      transition: all linear 0.1s;
    }

    .body {
      position: absolute;
      top: 0;
      z-index: 50;
      width: 100%;
      height: 100%;
      text-align: center;
      font-weight: bold;
      user-select: none;
      transition: all linear 0.1s;
      
      &.instructions {
        color: $c-main;
        padding-top: 220px;
        font-size: 18px;
        
        &.hidden {
          opacity: 0;
        }

        .controlIcon {
          margin-top: 10px;
          height: 50px;
        }
      }

      // &.player {
      //   opacity: 0;

      //   &.visible {
      //     opacity: 1;
      //   }
      // }

      &.result {
        padding-top: 250px;
        font-size: 36px;
        opacity: 0;

        &.visible {
          opacity: 1;
        }

        &.success {
          color: $c-main-lighter;
        }
        &.failure {
          color: $c-red-dark;
        }
      }
    }

    #holoSync {
      .redBar {
        fill: rgb(112, 0, 0)
      }
      .redTarget {
        fill: rgb(240, 0, 0)
      }
      .greenBar {
        fill: rgb(2, 68, 0)
      }
      .greenTarget {
        fill: rgb(0, 121, 0)
      }
      .blueBar {
        fill: rgb(11, 0, 112)
      }
      .blueTarget {
        fill: rgb(0, 16, 240)
      }

      .marker {
        fill: $c-white;
        opacity: 0.6;
      }
    }

    #waveMatch {
      .targetCircle {
        fill: $c-main-light;
      }

      .playerCircle {
        fill: $c-red;
      }

      .matchCircle {
        fill: $c-blue;
      }
    }

    #centerTarget {
      .crosshair {
        circle, line {
          stroke: $c-red;
          stroke-width: 2px;
          fill: transparent;
        }
      }
    }

    #circuitMaze {
      .circuit {
        &.off {
          fill: $c-blue;
        }
        &.dead {
          fill: $c-gray-20;
        }
        &.path {
          fill: $c-yellow;
        }
        &.target {
          fill: $c-red;
        }
        &.current {
          fill: $c-white;
        }
      }

      .path {
        stroke: $c-yellow;
        stroke-width: 3px;
      }
    }
  }
}

.ui.popup {
  background-color: $c-main-darkest;
  border: 1px solid $c-main-light;

  &:before {
    background-color: $c-main-darkest !important;
  }

  &.right:before {
    box-shadow: -1px 1px 0 0 $c-main-light !important;
  }

  .suitColorChoice {
    float: left;
    margin: 4px;
    border-radius: 25px;
    overflow: hidden;

    &.selected {
      border: 2px solid $c-main-lighter;
    }
  }
}

.healthBar {
  margin-bottom: 3px;

  .healthBarMax {
    position: relative;
    height: 100%;
    background-color: $c-black;
  }

  &.horiz {
    .healthBarCurr {
      height: 100%;
    }
  }
  &.vert {
    .healthBarCurr {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

.battleView {
  width: calc(100vw - 100px) !important;

  .mechContainer {
    width: 100%;
    position: relative;

    .mechFrame {
      position: absolute;
      top: 0;
      width: 368px;
      height: 428px;
      border: 4px solid $c-main-light;
      overflow: hidden;
      transition: opacity 1s;

      &.leftFrame {
        left: 0;
      }

      &.rightFrame {
        right: 0;
      }

      &.collapsed {
        opacity: 0;
      }

      .pilotFrame {
        position: absolute;
        width: 54px;
        height: 54px;
        border: 2px solid $c-main-light;

        &.head {
          left: 5px;
          top: 5px;
        }
        &.leftArm {
          right: 5px;
          top: 100px;
        }
        &.rightArm {
          left: 5px;
          top: 100px;
        }
        &.leftLeg {
          right: 5px;
          top: 270px;
        }
        &.rightLeg {
          left: 5px;
          top: 270px;
        }

        .healthBars {
          position: relative;
          top: 6px;

          .mechArmor, .pilotHealth, .pilotSpecial {
            height: 4px;
            width: 50px;
          }
  
          .mechArmor .healthBarCurr {
            background-color: $c-gray-75;
          }
          .pilotHealth .healthBarCurr {
            background-color: $c-red;
          }
          .pilotSpecial .healthBarCurr {
            background-color: $c-blue;
          }
        }
      }
    }

    .rageBar {
      height: 22px;
      width: 368px;
      float: right;
      top: 442px;
      position: relative;
      border-radius: 14px 4px;
      overflow: hidden;
      opacity: 0;
      transition: all linear 0.2s;

      &.visible {
        opacity: 1.0;
      }

      .healthBarMax {
        background-color: $c-gray-20;
      }
      .healthBarCurr {
        background-color: $c-rage-none;
        transition: all linear 0.2s;
      }

      &.lowRage {
        box-shadow: 0px 0px 4px 1px $c-rage-low;
        .healthBarCurr {
          background-color: $c-rage-low;
        }
      }
      &.medRage {
        box-shadow: 0px 0px 6px 2px $c-rage-med;
        .healthBarCurr {
          background-color: $c-rage-med;
        }
      }
      &.highRage {
        box-shadow: 0px 0px 8px 3px $c-rage-high;
        .healthBarCurr {
          background-color: $c-rage-high;
        }
      }
    }

    .recallBtn {
      position: relative;
      top: 440px;
      width: 370px;
      padding: 4px 12px;
      border-radius: 14px 4px;
      background-color: $c-red-darker;
      color: $c-red-light;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      transition: all linear 0.2s;
      opacity: 0;
      pointer-events: none;

      &.active {
        opacity: 1.0;
        pointer-events: unset;
      }

      &:hover {
        background-color: $c-red-dark;
        color: $c-red-lighter;
      }
    }

    .commLog {
      position: relative;
      top: 454px;
      width: 370px;
      height: calc(100vh - 650px);
      overflow-y: auto;
      transition: all linear 0.2s;
      opacity: 0;

      &.active {
        opacity: 1.0;
      }

      .commItem {
        font-size: 12px;
        background-color: $c-main-darkest;
        border-radius: 12px 4px;
        margin-bottom: 4px;

        .commHeader {
          padding: 0 10px;
          border-radius: 12px 4px 0 0;
          background-color: $c-main-dark;
          color: $c-main-darkest;
          font-weight: bold;
        }

        .commBody {
          padding: 0 10px;
          color: $c-main-light;
        }
      }
    }
  }

  .messageLogHeader {
    position: relative;
    padding: 5px;
    text-align: center;
    background-color: $c-main-dark;
    color: $c-main-light;
    font-size: 18px;
    font-weight: 700;

    .ticker {
      position: absolute;
      right: 8px;
      font-size: 12px;
      font-weight: normal;
      color: $c-main;
    }

    .timeWarning {
      display: inline-block;
      font-weight: 700;
      color: $c-yellow;
      margin: 0 30px;
    }

    .battleControl {
      margin: 0 12px;

      &.active {
        cursor: auto;
        pointer-events: none;

        color: $c-main-lighter;
      }

      &.disabled {
        pointer-events: none;
        color: $c-main;
      }
    }
  }

  .messageLog {
    height: calc(100vh - 200px);
    overflow: auto;
    border: 2px solid $c-main-dark;
    padding: 8px;

    .pilotMessage, .enemyMessage, .specialMessage, .endMessage {
      width: 60%;
      margin-top: 4px;

      .messageHeader {
        float: left;
        height: 19px;
        width: 100%;
        padding: 0 3px;
        font-size: 12px;
        font-weight: 700;
      }

      .skillIcon {
        float: left;
      }

      .messageText {
        float: left;
        width: 100%;
        padding: 2px 4px;
      }
    }

    .pilotMessage {
      float: left;

      .messageHeader {
        background-color: $c-main-light;
        color: $c-main-darkest;
        border-top-right-radius: 8px;
      }

      .skillIcon {
        color: $c-main-darkest;
      }

      .messageText {
        background-color: $c-main-darkest;
        color: $c-main-lighter;
        border-bottom-right-radius: 8px;
      }
    }

    .specialMessage {
      float: left;

      .messageHeader {
        background-color: $c-blue-lighter;
        color: $c-blue-darkest;
        border-top-right-radius: 8px;
      }

      .skillIcon {
        color: $c-blue-darkest;
      }

      .messageText {
        background-color: $c-blue-darkest;
        color: $c-blue-lighter;
        border-bottom-right-radius: 8px;
      }
    }

    .enemyMessage {
      float: right;

      .messageHeader {
        background-color: $c-red-dark;
        color: $c-red-darkest;
        border-top-left-radius: 8px;
    }

      .skillIcon {
        color: $c-red-darkest;
      }

      .messageText {
        background-color: $c-red-darkest;
        color: $c-red-light;
        border-bottom-left-radius: 8px;
      }
    }

    .endMessage {
      float: left;
      margin-left: 20%;

      .messageHeader {
        background-color: $c-gray-50;
        color: $c-black;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .skillIcon {
        color: $c-red-darkest;
      }

      .messageText {
        background-color: $c-gray-20;
        color: $c-gray-80;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .titleCard {
      text-align: center;
      position: relative;
      height: 100%;
      
      .bgLogo {
        opacity: 0.15;
        position: absolute;
        display: inline-block;
        top: calc(50% - 150px);
        left: calc(50% - 150px);     
      }

      .mechCard {
        position: absolute;
        font-size: 40px;
        font-weight: 700;
        width: 100%;
        transition: opacity 1s;
        opacity: 0;

        &.show {
          opacity: 1.0;
        }

        &.top {
          top: 20vh;
        }
        &.middle {
          top: calc(50% - 5px)
        }
        &.bottom {
          bottom: 20vh;
        }
      }
    }

    .statBlock {
      float: left;
      clear: left;
      margin-bottom: 20px;

      .pilotPortrait {
        float: left;
        margin-right: 15px;
      }

      .pilotName {
        float: left;
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 700;
        color: $c-main-lighter;
      }

      .statRows {
        float: left;

        .statRow {
          float: left;
          clear: left;
  
          .statLabel {
            float: left;
            width: 140px;
            font-weight: 700;
            color: $c-main-light;  
          }
  
          .statValue {
            float: left;
            width: 70px;
            color: $c-main-light;
          }
        }
      }
    }
  }

  .planetCleared {
    background-color: $c-main-verydark;
    border: 2px solid $c-main-light;
    border-radius: 24px 10px 24px 10px;
    color: $c-main-light;
    padding: 20px;

    h2 {
      text-align: center;
      border-bottom: 1px solid $c-main-light;
      margin-bottom: 20px;
    }

    .returnBtn {
      margin: auto;
    }
  }

  .messageLog, .planetCleared {
    .rewardRow {
      width: 100%;
      height: 30px;
      width: 100%;
      margin-bottom: 20px;
      font-size: 18px;
      color: $c-main-light;  

      .rewardLabel {
        float: left;
        font-weight: 700;
    }

      .rewardValue {
        float: right;
      }
    }
  }

  .enemyBattleStats {
    padding: 12px;
    border: 2px solid $c-main;
    background-color: $c-main-verydark;
    border-radius: 20px 8px 20px 8px;
    margin-bottom: 20px;
    cursor: pointer;
    color: $c-main-dark;

    &:hover {
      border-color: $c-main-lightest;
      color: $c-main-lighter;
    }

    &.selected {
      background-color: $c-main-darkest;
      color: $c-main-lighter;
    }

    &.defeated {
      background-color: $c-gray-20;
      color: $c-gray-50;
      border-color: $c-gray-50;
      pointer-events: none;
    }

    .header, .subheader {
      font-weight: 700;
      margin-bottom: 6px;
    }

    .header {
      font-size: 20px;
    }
    .subheader {
      font-size: 16px;
    }

    .data {
      margin-left: 12px;
    }
  }
}

.iconBtn {
  color: $c-main-light;
  cursor: pointer;

  &:hover {
    color: $c-main-lighter;
  }
}

.Toastify__toast.Toastify__toast--default.myToast {
  background-color: $c-main-darker;
  border: 3px solid $c-main-light;
  border-radius: 22px 8px;
  font-family: $font-main;
  font-weight: 700;

  .myToastBody {
    color: $c-main-lighter;
  }

  &.warning {
    background-color: $c-red-darker;
    border-color: $c-red;

    .myToastBody {
      color: $c-red;
    }
  }
}

$attributes: 'conf', 'prow', 'reas', 'fort', 'wis', 'dar', 'emp', 'hum';

@each $attribute in $attributes {
  .color-attr-#{$attribute} {
    color: nth($attrColors, index($attributes, $attribute)) !important;
  }
  .bg-attr-#{$attribute} {
    background-color: nth($attrColors, index($attributes, $attribute)) !important;
  }
}

@for $i from 1 through 6 {
  .bg-team-#{$i} {
    background-color: nth($teamColors, $i);
  }
  .border-team-#{$i} {
    border-color: nth($teamColors, $i);
  }
}

@keyframes flickerAnimation {
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}

.spinning {
  -webkit-animation:spin 8s linear infinite;
  -moz-animation:spin 8s linear infinite;
  animation:spin 8s linear infinite;  
}