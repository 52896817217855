$c-white: #ffffff;
$c-white-trans: #ffffffbe;
$c-gray-98: #f3f3f3;
$c-gray-95: #EDEDED;
$c-gray-90: #E3E3E3;
$c-gray-85: #D9D9D9;
$c-gray-80: #CCCCCC;
$c-gray-75: #C0C0C0;
$c-gray-70: #B0B0B0;
$c-gray-60: #999999;
$c-gray-50: #808080;
$c-gray-45: #727272;
$c-gray-40: #696969;
$c-gray-30: #4D4D4D;
$c-gray-20: #363636;
$c-gray-15: #222222;
$c-gray-10: #171717;
$c-black: #000000;

$c-main:        rgb(0, 128, 0);
$c-main-light:  #00aa00;
$c-main-lighter:#00cc00;
$c-main-lightest:#01ff01;
$c-main-dark:   #006600;
$c-main-darker: #004400;
$c-main-darkest:#002200;
$c-main-verydark:#001300;

$c-blue:          #0000ff;
$c-blue-light:    #4848ff;
$c-blue-lighter:  #8484ff;
$c-blue-lightest: #acacff;
$c-blue-dark:     #0000b4;
$c-blue-darker:   #00007e;
$c-blue-darkest:  #000044;

$c-red:         #ff0000;
$c-red-light:   #ff5353;
$c-red-lighter: #ff8383;
$c-red-dark:    #b40000;
$c-red-darker:  #690000;
$c-red-darkest: #300000;

$c-yellow:      #ffff00;

$c-attr-conf: #c40101;
$c-attr-prow: #cf6f02;
$c-attr-reas: #ccbf02;
$c-attr-fort: #00c400;
$c-attr-wis:  #009bca;
$c-attr-dar:  #004fc5;
$c-attr-emp:  #c200b2;
$c-attr-hum:  #a1a1a1;

$attrColors: $c-attr-conf, $c-attr-prow, $c-attr-reas, $c-attr-fort, $c-attr-wis, $c-attr-dar, $c-attr-emp, $c-attr-hum;

$c-team-1: #ff5757;
$c-team-2: #ffb74a;
$c-team-3: #c5c900;
$c-team-4: #56d13e;
$c-team-5: #609ef0;
$c-team-6: #dd5ed7;

$teamColors: $c-team-1, $c-team-2, $c-team-3, $c-team-4, $c-team-5, $c-team-6;

$c-danger-0: #cccccc;
$c-danger-1: #2dc200;
$c-danger-2: #d3e200;
$c-danger-3: #fa6603;
$c-danger-4: #ff0000;
$c-danger-5: #9e00ce;

$dangerColors: $c-danger-0, $c-danger-1, $c-danger-2, $c-danger-3, $c-danger-4, $c-danger-5;

$c-rage-none: rgb(173, 162, 2);
$c-rage-low: rgb(255, 238, 0);
$c-rage-med: rgb(255, 166, 0);
$c-rage-high: rgb(255, 0, 0);
